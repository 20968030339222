import React, { useContext } from 'react';
import { PageProps, graphql } from 'gatsby';
import CasesDetail from '@/components/Cases/CasesDetail';
import { Context } from '@/components/Layout';

const CasesPage: React.FC<PageProps<GatsbyTypes.CasesPageQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  return !loading && <CasesDetail data={data} />;
};

export default CasesPage;

export const query = graphql`
  query CasesPage($id: String!) {
    microcmsCase(id: { eq: $id }, caseId: { ne: "template" }) {
      id
      body {
        plain
        rich
        asset {
          assetBody {
            rich
          }
        }
      }
      customer
      caseId
      title
      description
      keyword
      publishedAt(formatString: "YYYY/MM/DD")
      tag {
        id
        tag
      }
      thumbnail {
        url
      }
      contact {
        button
        id
        tayori
        title
      }
    }
    microcmsEnv {
      commonThumbnail {
        url
      }
    }
  }
`;
